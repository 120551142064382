import React, { useEffect, useState, useRef } from 'react';
import {
  useChat,
  useLocalParticipant,
  useTrackTranscription,
} from "@livekit/components-react";
import {
  LocalParticipant,
} from "livekit-client";

export function TranscriptionTile({
  agentAudioTrack,
  accentColor,
}) {
  const agentMessages = useTrackTranscription(agentAudioTrack);
  const { localParticipant } = useLocalParticipant();
  const localMessages = useTrackTranscription({
    publication: localParticipant.microphoneTrack,
    source: 'microphone',
    participant: localParticipant,
  });

  const [transcripts, setTranscripts] = useState(new Map());
  const [messages, setMessages] = useState([]);
  const { chatMessages, send: sendChat } = useChat();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const newTranscripts = new Map(transcripts);
    
    agentMessages.segments.forEach((s) =>
      newTranscripts.set(
        s.id,
        segmentToChatMessage(
          s,
          newTranscripts.get(s.id),
          agentAudioTrack.participant
        )
      )
    );
    localMessages.segments.forEach((s) =>
      newTranscripts.set(
        s.id,
        segmentToChatMessage(
          s,
          newTranscripts.get(s.id),
          localParticipant
        )
      )
    );

    setTranscripts(newTranscripts);

    const allMessages = Array.from(newTranscripts.values());
    for (const msg of chatMessages) {
      const isAgent =
        msg.from?.identity === agentAudioTrack.participant?.identity;
      const isSelf =
        msg.from?.identity === localParticipant.identity;
      let name = msg.from?.name;
      if (!name) {
        if (isAgent) {
          name = "Agent";
        } else if (isSelf) {
          name = "You";
        } else {
          name = "Unknown";
        }
      }
      allMessages.push({
        name,
        message: msg.message,
        timestamp: msg.timestamp,
        isSelf: isSelf,
      });
    }
    allMessages.sort((a, b) => b.timestamp - a.timestamp); // Reverse sort
    setMessages(allMessages);
  }, [
    agentMessages.segments,
    localMessages.segments,
    chatMessages,
    localParticipant,
    agentAudioTrack.participant,
  ]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex flex-col h-full bg-gray-50 rounded-lg shadow-lg overflow-hidden">
      <h2 className="text-2xl font-bold py-4 px-6 bg-gray-800 text-white">Transcription</h2>
      <div className="flex-grow overflow-y-auto p-4 flex flex-col-reverse">
        <div ref={messagesEndRef} />
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`mb-4 p-4 rounded-lg shadow ${
              msg.isSelf ? 'bg-blue-100 ml-auto' : 'bg-white'
            } max-w-[80%] ${msg.isSelf ? 'self-end' : 'self-start'}`}
          >
            <p className={`font-semibold mb-1 ${msg.isSelf ? 'text-blue-700' : 'text-gray-700'}`}>
              {msg.name}:
            </p>
            <p className="text-gray-800 text-lg leading-relaxed">{msg.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function segmentToChatMessage(
  s,
  existingMessage,
  participant
) {
  return {
    message: s.final ? s.text : `${s.text} ...`,
    name: participant instanceof LocalParticipant ? "You" : "Agent",
    isSelf: participant instanceof LocalParticipant,
    timestamp: existingMessage?.timestamp ?? Date.now(),
  };
}