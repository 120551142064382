import React, { useState, useEffect, useCallback } from 'react';
import { FaEye, FaChevronDown } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';

// Progress Modal Component
const ProgressModal = ({ isOpen, progress }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-96">
        <h3 className="text-lg font-medium mb-4">Evaluating Interview</h3>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div 
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="text-center text-sm text-gray-600">
          {progress < 33 && "Fetching interview data..."}
          {progress >= 33 && progress < 66 && "Analyzing interview..."}
          {progress >= 66 && progress < 100 && "Generating evaluation..."}
          {progress === 100 && "Completing evaluation..."}
        </p>
      </div>
    </div>
  );
};

// Transcript Modal Component
const TranscriptModal = ({ isOpen, onClose, transcript }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-4xl max-h-[80vh] overflow-hidden flex flex-col m-4">
        <div className="flex justify-between items-center mb-4 border-b pb-4">
          <h3 className="text-lg font-medium text-gray-900">Interview Transcript</h3>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 p-2"
          >
            <span className="text-xl">×</span>
          </button>
        </div>
        <div className="overflow-y-auto flex-grow">
          <pre className="whitespace-pre-wrap text-sm text-gray-700 p-4 bg-gray-50 rounded">
            {transcript || 'No transcript available'}
          </pre>
        </div>
      </div>
    </div>
  );
};

// Main Component
const PendingInterviews = inject('store')(observer(({ store }) => {
  // States
  const [selectedJob, setSelectedJob] = useState('');
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [evaluation, setEvaluation] = useState(null);
  const [error, setError] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [evaluatingId, setEvaluatingId] = useState(null);
  
  // Transcript states
  const [showTranscript, setShowTranscript] = useState(false);
  const [currentTranscript, setCurrentTranscript] = useState('');
  const [loadingTranscript, setLoadingTranscript] = useState(false);

  // Fetch job list
  const refreshJobList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await store.api.post('/user/JobDetails/view');
      if (response.data && Array.isArray(response.data)) {
        store.joblist = response.data.map((job) => ({
          jobid: job.jobId,
          jobname: job.jobname,
          jobdescription: job.jobdescription,
        }));
      } else {
        store.joblist = [];
        console.error("Invalid job list data format");
      }
    } catch (error) {
      console.error("Failed to fetch job list:", error);
      store.joblist = [];
    } finally {
      setIsLoading(false);
    }
  }, [store]);

  // Fetch pending interviews
  const fetchPendingInterviews = async () => {
    try {
      setIsLoading(true);
      const response = await store.api.get('/user/pending');
      
      if (response?.data?.data && Array.isArray(response.data.data)) {
        setInterviews(response.data.data);
      } else if (Array.isArray(response.data)) {
        setInterviews(response.data);
      } else {
        console.error('Unexpected response format:', response);
        setInterviews([]);
      }
    } catch (error) {
      console.error('Error fetching pending interviews:', error);
      setInterviews([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshJobList();
    fetchPendingInterviews();
  }, [refreshJobList]);

  // Handle view transcript
  const handleViewTranscript = async (interview_link) => {
    try {
      setLoadingTranscript(true);
      const response = await store.api.get(`/user/interview/TranscriptAndJD/${interview_link}`);
      
      if (response.data?.success && response.data?.finalData?.transcript) {
        setCurrentTranscript(response.data.finalData.transcript);
        setShowTranscript(true);
      } else {
        throw new Error('Failed to load transcript');
      }
    } catch (error) {
      console.error('Error fetching transcript:', error);
      store.notifications?.error?.('Failed to load transcript');
    } finally {
      setLoadingTranscript(false);
    }
  };

  // Handle evaluation
  const evaluateResult = async (interview_link) => {
    try {
      setEvaluatingId(interview_link);
      setShowProgress(true);
      setProgress(0);
      setError(null);
  
      // Fetch interview data
      setProgress(20);
      const response = await store.api.get(`/user/interview/TranscriptAndJD/${interview_link}`);
      
      if (!response.data?.success) {
        throw new Error('Failed to fetch interview data');
      }
  
      setProgress(40);
      const { jobDescription, transcript } = response.data.finalData;
  
      // Get AI evaluation
      setProgress(60);
      const evaluationResponse = await store.api.post('/ai/evaluate-interview', {
        'interviewHistory': transcript,
        'jobDescription': jobDescription
      });
      
      if (!evaluationResponse.data) {
        throw new Error('Failed to get AI evaluation');
      }
  
      setProgress(80);
      const evaluationResult = evaluationResponse.data;
      setEvaluation(evaluationResult);
  
      // Save evaluation
      const saveResponse = await store.api.post('/user/interview/save', {
        evaluation: evaluationResult,
        interview_link: interview_link
      });
  
      setProgress(90);
  
      // Handle different success status codes
      switch (saveResponse.status) {
        case 200:
        case 201:
          await fetchPendingInterviews(); // Refresh the list
          store.notifications?.success?.('Interview evaluated and saved successfully');
          break;
        case 202:
          store.notifications?.success?.('Interview evaluation queued for processing');
          break;
        default:
          if (saveResponse.status >= 200 && saveResponse.status < 300) {
            store.notifications?.success?.('Interview evaluation completed');
          } else {
            throw new Error(`Unexpected response status: ${saveResponse.status}`);
          }
      }
  
      setProgress(100);
  
    } catch (error) {
      console.error('Error during interview evaluation:', error);
      setError(error.message || 'Failed to evaluate interview. Please try again.');
      store.notifications?.error?.(error.message || 'Failed to evaluate interview');
    } finally {
      setTimeout(() => {
        setShowProgress(false);
        setProgress(0);
        setEvaluatingId(null);
      }, 1000);
    }
  };

  // Filter interviews based on selected job
  const filteredInterviews = Array.isArray(interviews) ? 
    (selectedJob
      ? interviews.filter(interview => interview.jobId === parseInt(selectedJob))
      : interviews)
    : [];

  // Format date helper function
  const formatDate = (timestamp) => {
    try {
      return new Date(timestamp).toLocaleDateString();
    } catch (error) {
      return timestamp;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Modals */}
      <ProgressModal isOpen={showProgress} progress={progress} />
      <TranscriptModal 
        isOpen={showTranscript}
        onClose={() => setShowTranscript(false)}
        transcript={currentTranscript}
      />

      {/* Job Selection Dropdown */}
      <div className="mb-6">
        <label htmlFor="jobSelect" className="block text-sm font-medium text-gray-700 mb-2">
          Select Job
        </label>
        <div className="relative">
          <select
            id="jobSelect"
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">All Jobs</option>
            {Array.isArray(store.joblist) && store.joblist.map(job => (
              <option key={job.jobid} value={job.jobid}>{job.jobname}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <FaChevronDown />
          </div>
        </div>
      </div>

      {/* Interviews Table */}
      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Candidate
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Job Title
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Duration(Minutes)
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Transcript
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Evaluate
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredInterviews.length > 0 ? (
                filteredInterviews.map(interview => (
                  <tr key={interview._id || interview.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {interview.candidate_name || interview.candidateName}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {interview.job_name || interview.jobTitle}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {formatDate(interview.timestamp || interview.date)}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {interview.duration ? Math.floor(interview.duration) : '-'}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <button
                        onClick={() => handleViewTranscript(interview.interview_link)}
                        disabled={loadingTranscript}
                        className="text-blue-600 hover:text-blue-900 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-1"
                      >
                        <FaEye className="inline" />
                        {loadingTranscript ? 'Loading...' : 'View'}
                      </button>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <button
                        onClick={() => evaluateResult(interview.interview_link)}
                        disabled={evaluatingId === interview.interview_link}
                        className={`text-blue-600 hover:text-blue-900 ${
                          evaluatingId === interview.interview_link ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        {evaluatingId === interview.interview_link ? 'Evaluating...' : 'Evaluate'}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="px-5 py-5 text-center text-gray-500">
                    No pending interviews found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {error && (
        <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}
    </div>
  );
}));

export default PendingInterviews;