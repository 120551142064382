import React, { useState, useEffect, useCallback } from 'react';
import { FaBriefcase, FaUsers, FaCalendarAlt, FaEnvelope, FaClock, FaCheckCircle, FaTimes, FaSpinner } from 'react-icons/fa';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import VirtualInterviewRoom from './VirtualInterviewRoom';

// Select component with enhanced styling
const Select = ({ options, onChange, placeholder, isDisabled, value }) => (
  <select 
    onChange={(e) => onChange(e.target.value)} 
    disabled={isDisabled}
    value={value || ""}
    className={`w-full p-3 border rounded-lg bg-white shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent
      ${isDisabled ? 'bg-gray-100 cursor-not-allowed' : 'hover:border-blue-500 cursor-pointer'}`}
  >
    <option value="">{placeholder}</option>
    {options.map(option => (
      <option key={option.value} value={option.value}>{option.label}</option>
    ))}
  </select>
);

// Enhanced Button component
const Button = ({ onClick, children, className, disabled, variant = 'primary' }) => {
  const baseStyles = 'px-4 py-2 rounded-lg font-medium transition-all duration-200 ease-in-out flex items-center justify-center';
  const variants = {
    primary: 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
    danger: 'bg-red-600 text-white hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2',
    secondary: 'bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
  };

  return (
    <button 
      onClick={onClick} 
      className={`${baseStyles} ${variants[variant]} ${className} ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

// Modal component with improved styling
const Modal = ({ isOpen, onClose, title, children, loading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-2xl max-w-md w-full transform transition-all">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-gray-900">{title}</h2>
            {!loading && (
              <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <FaTimes size={20} />
              </button>
            )}
          </div>
          {loading && (
            <div className="mb-6">
              <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                <div className="h-full bg-blue-600 animate-progress rounded-full" />
              </div>
              <div className="flex items-center justify-center mt-4 text-sm text-gray-600">
                <FaSpinner className="animate-spin mr-2" />
                Sending invitation...
              </div>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

// Notification component
const Notification = ({ show, type = 'success', message, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(onClose, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  const bgColor = type === 'success' 
    ? 'bg-green-50 border-green-200' 
    : 'bg-red-50 border-red-200';
  const textColor = type === 'success' ? 'text-green-800' : 'text-red-800';
  const icon = type === 'success' ? <FaCheckCircle className="h-5 w-5" /> : <FaTimes className="h-5 w-5" />;

  return (
    <div className="fixed top-4 right-4 z-50 w-96 transform transition-all">
      <div className={`${bgColor} ${textColor} p-4 rounded-lg border shadow-lg`}>
        <div className="flex items-start">
          <div className="flex-shrink-0">{icon}</div>
          <div className="ml-3 w-full">
            <p className="font-medium">{type === 'success' ? 'Success' : 'Error'}</p>
            <p className="mt-1 text-sm opacity-90">{message}</p>
          </div>
          <button onClick={onClose} className="ml-4">
            <FaTimes className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

// Main InterviewScheduling component
const InterviewScheduling = ({ store }) => {
  // State management
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [showInterviewRoom, setShowInterviewRoom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [notification, setNotification] = useState({
    show: false,
    type: 'success',
    message: ''
  });

  // Fetch candidates for selected job
  const fetchCandidates = useCallback(async (jobId) => {
    try {
      setIsLoading(true);
      const response = await store.api.post('/user/CandidatesByJobId/find', { "jobid": jobId });
      setCandidates(response.data);
    } catch (error) {
      console.error("Failed to fetch candidates:", error);
      showNotification('error', 'Failed to fetch candidates');
    } finally {
      setIsLoading(false);
    }
  }, [store.api]);

  // Fetch job list
  const refreshJobList = useCallback(async () => {
    try {
      setIsFetchingData(true);
      let response = await store.api.post('/user/JobDetails/view');
      store.joblist = response.data.map((job) => ({
        jobid: job.jobId,
        jobname: job.jobname,
        jobdescription: job.jobdescription,
      }));
    } catch (error) {
      console.error("Failed to fetch job list:", error);
      showNotification('error', 'Failed to fetch job list');
    } finally {
      setIsFetchingData(false);
    }
  }, [store]);

  useEffect(() => {
    refreshJobList();
  }, [refreshJobList]);

  // Notification helper
  const showNotification = (type, message) => {
    setNotification({ show: true, type, message });
  };

  // Event handlers
  const handleJobSelect = (jobId) => {
    const job = store.joblist.find(job => job.jobid === parseInt(jobId));
    setSelectedJob(job);
    if (job) {
      fetchCandidates(job.jobid);
    }
    setSelectedCandidate(null);
    setSelectedDate('');
    setSelectedTime('');
  };

  const handleCandidateSelect = (candidateId) => {
    setSelectedCandidate(candidates.find(candidate => candidate.resumeId === candidateId));
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleScheduleClick = () => {
    if (selectedJob && selectedCandidate && selectedDate && selectedTime) {
      setIsConfirmationOpen(true);
    } else {
      showNotification('error', 'Please select all required fields before scheduling.');
    }
  };

  const handleConfirmSchedule = async () => {
    if (selectedJob && selectedCandidate && selectedDate && selectedTime) {
      try {
        setIsLoading(true);
        const response = await store.api.post('/user/InterviewDetails/save', {
          "jobId": selectedJob.jobid,
          "candidateName": selectedCandidate.name,
          "candidateEmail": selectedCandidate.email,
          "jobDescription": selectedJob.jobdescription,
          "jobName": selectedJob.jobname,
          "interviewDate": selectedDate,
          "interviewTime": selectedTime
        });

        if (response.data.success) {
          showNotification('success', `Interview invitation sent successfully to ${selectedCandidate.name}!`);
          setIsConfirmationOpen(false);
          handleClearSelection();
        } else {
          showNotification('error', 'Failed to schedule interview. Please try again.');
        }
      } catch (error) {
        console.error("Error scheduling interview:", error);
        showNotification('error', 'An error occurred while scheduling the interview.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClearSelection = () => {
    setSelectedJob(null);
    setSelectedCandidate(null);
    setSelectedDate('');
    setSelectedTime('');
  };

  // Generate date options for the next 14 days
  const dateOptions = [...Array(14)].map((_, i) => {
    const date = moment().add(i, 'days');
    return { 
      value: date.format('YYYY-MM-DD'), 
      label: date.format('dddd, MMMM D, YYYY')
    };
  });

  // Generate time options from 9 AM to 5 PM in 30-minute intervals
  const timeOptions = [...Array(17)].map((_, i) => {
    const time = moment().set({ hour: 9, minute: 0 }).add(i * 30, 'minutes');
    return { 
      value: time.format('HH:mm'), 
      label: time.format('h:mm A')
    };
  });

  if (showInterviewRoom) {
    return (
      <VirtualInterviewRoom 
        isRecruiter={true}
        candidateName={selectedCandidate?.name || 'Candidate'}
        jobTitle={selectedJob?.jobname || 'Job'}
        onExit={() => setShowInterviewRoom(false)}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4">
      <div className="max-w-6xl mx-auto">
        <Notification
          show={notification.show}
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(prev => ({ ...prev, show: false }))}
        />

        <h1 className="text-3xl font-bold text-gray-900 mb-8">Schedule Interview</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Job Selection */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
              <FaBriefcase className="mr-2 text-blue-600" /> Select Job
            </h2>
            <Select
              options={store.joblist.map(job => ({ value: job.jobid, label: job.jobname }))}
              onChange={handleJobSelect}
              placeholder="Choose a job position"
              isDisabled={isFetchingData}
              value={selectedJob?.jobid}
            />
          </div>

          {/* Candidate Selection */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
              <FaUsers className="mr-2 text-green-600" /> Select Candidate
            </h2>
            <Select
              options={candidates.map(candidate => ({ 
                value: candidate.resumeId, 
                label: `${candidate.name} (${candidate.email})` 
              }))}
              onChange={handleCandidateSelect}
              placeholder="Choose a candidate"
              isDisabled={!selectedJob || isLoading}
              value={selectedCandidate?.resumeId}
            />
          </div>

          {/* Date Selection */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
              <FaCalendarAlt className="mr-2 text-orange-600" /> Select Date
            </h2>
            <Select
              options={dateOptions}
              onChange={handleDateSelect}
              placeholder="Choose a date"
              isDisabled={!selectedCandidate}
              value={selectedDate}
            />
          </div>

          {/* Time Selection */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
              <FaClock className="mr-2 text-purple-600" /> Select Time
            </h2>
            <Select
              options={timeOptions}
              onChange={handleTimeSelect}
              placeholder="Choose a time"
              isDisabled={!selectedDate}
              value={selectedTime}
            />
          </div>
        </div>

        {/* Interview Details */}
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200 mb-8">
          <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
            <FaCalendarAlt className="mr-2 text-indigo-600" /> Interview Details
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="space-y-2">
              <p className="text-gray-600"><strong>Job:</strong> {selectedJob?.jobname || 'Not selected'}</p>
              <p className="text-gray-600"><strong>Candidate:</strong> {selectedCandidate?.name || 'Not selected'}</p>
            </div>
            <div className="space-y-2">
            <p className="text-gray-600"><strong>Date:</strong> {selectedDate ? 
                moment(selectedDate).format('dddd, MMMM D, YYYY') : 'Not selected'}</p>
              <p className="text-gray-600"><strong>Time:</strong> {selectedTime ? 
                moment(selectedTime, 'HH:mm').format('h:mm A') : 'Not selected'}</p>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <Button 
              onClick={handleClearSelection} 
              variant="danger"
              className="min-w-[120px]"
            >
              <FaTimes className="mr-2" /> Clear
            </Button>
            <Button 
              onClick={handleScheduleClick} 
              variant="primary"
              disabled={!(selectedJob && selectedCandidate && selectedDate && selectedTime)}
              className="min-w-[120px]"
            >
              <FaCalendarAlt className="mr-2" /> Schedule
            </Button>
          </div>
        </div>

        {/* Confirmation Modal */}
        <Modal
          isOpen={isConfirmationOpen}
          onClose={() => !isLoading && setIsConfirmationOpen(false)}
          title="Confirm Interview Schedule"
          loading={isLoading}
        >
          <div className="space-y-6">
            <div className="bg-gray-50 rounded-lg p-4 space-y-3">
              <div className="flex items-center space-x-3 text-gray-700">
                <FaBriefcase className="text-blue-600" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Job Position</p>
                  <p className="font-medium">{selectedJob?.jobname}</p>
                </div>
              </div>
              
              <div className="flex items-center space-x-3 text-gray-700">
                <FaUsers className="text-green-600" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Candidate</p>
                  <p className="font-medium">{selectedCandidate?.name}</p>
                </div>
              </div>

              <div className="flex items-center space-x-3 text-gray-700">
                <FaCalendarAlt className="text-orange-600" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Date</p>
                  <p className="font-medium">
                    {selectedDate ? moment(selectedDate).format('dddd, MMMM D, YYYY') : ''}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-3 text-gray-700">
                <FaClock className="text-purple-600" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Time</p>
                  <p className="font-medium">
                    {selectedTime ? moment(selectedTime, 'HH:mm').format('h:mm A') : ''}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <Button 
                onClick={() => setIsConfirmationOpen(false)} 
                variant="secondary"
                disabled={isLoading}
                className="min-w-[100px]"
              >
                Cancel
              </Button>
              <Button 
                onClick={handleConfirmSchedule} 
                variant="primary"
                disabled={isLoading}
                className="min-w-[140px]"
              >
                {isLoading ? (
                  <div className="flex items-center">
                    <FaSpinner className="animate-spin mr-2" />
                    Sending...
                  </div>
                ) : (
                  <>
                    <FaEnvelope className="mr-2" /> Send Invite
                  </>
                )}
              </Button>
            </div>
          </div>
        </Modal>

        {/* Add Tailwind animations */}
        <style jsx global>{`
          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
          @keyframes progress {
            0% { width: 0% }
            100% { width: 100% }
          }
          .animate-spin {
            animation: spin 1s linear infinite;
          }
          .animate-progress {
            animation: progress 2s linear infinite;
          }
        `}</style>
      </div>
    </div>
  );
};

export default inject('store')(observer(InterviewScheduling));
