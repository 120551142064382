import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import {
  LiveKitRoom,
  VideoConference,
  RoomAudioRenderer,
  useParticipants,
  useLiveKitRoom,
  useVoiceAssistant,
  useDataChannel
} from '@livekit/components-react';
import { DataPacket_Kind, RoomEvent } from 'livekit-client';
import '@livekit/components-styles';
import { TranscriptionTile } from './TranscriptionTile';

const RoomContent = ({ interviewDetails, interviewLink, onEndInterview }) => {
  const { room } = useLiveKitRoom();
  const participants = useParticipants();
  const voiceAssistant = useVoiceAssistant();
  const [transcripts, setTranscripts] = useState([]);
  const [aiResponse, setAiResponse] = useState('');

  const handleAiResponse = useCallback((message) => {
    setAiResponse(message);
  }, []);

  const handleDataReceived = useCallback((msg) => {
    if (msg.kind === DataPacket_Kind.RELIABLE) {
      const decodedData = new TextDecoder().decode(msg.payload);
      try {
        const data = JSON.parse(decodedData);
        console.log('Received data:', data);
        if (data.type === 'transcript_update') {
          setTranscripts(prev => [...prev, {
            text: data.entry.text,
            isAgent: data.entry.isAgent,
            timestamp: data.entry.timestamp
          }]);
        } else if (data.type === 'ai_response') {
          handleAiResponse(data.message);
        }
      } catch (error) {
        console.error('Error parsing received data:', error);
      }
    }
  }, [handleAiResponse]);

  useDataChannel(handleDataReceived);

  useEffect(() => {
    if (room) {
      console.log('Room connected');
      console.log('Participants:', participants);

      const handleRoomConnected = () => {
        if (interviewDetails) {
          room.localParticipant.publishData(JSON.stringify({
            type: 'start_interview',
            ...interviewDetails,
            interviewLink
          }), DataPacket_Kind.RELIABLE);
        }
      };

      room.on(RoomEvent.Connected, handleRoomConnected);

      return () => {
        room.off(RoomEvent.Connected, handleRoomConnected);
      };
    }
  }, [room, interviewDetails, interviewLink, participants]);

  return (
    <div className="flex w-full h-full">
      <div className="w-3/4 h-full">
        <VideoConference />
      </div>
      <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
        {voiceAssistant.audioTrack && (
          <TranscriptionTile
            agentAudioTrack={voiceAssistant.audioTrack}
            accentColor='black'
            transcripts={transcripts}
          />
        )}
        {/* <button
          onClick={onEndInterview}
          className="mt-4 w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
        >
          End Interview
        </button> */}
      </div>
      <RoomAudioRenderer />
    </div>
  );
};

const VirtualInterviewRoom = ({ store }) => {
  const [token, setToken] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInterviewStarted, setIsInterviewStarted] = useState(false);
  const [isInterviewEnded, setIsInterviewEnded] = useState(false);
  const { room } = useLiveKitRoom(); // Get the room instance

  const { interviewLink } = useParams();
  const history = useHistory();

  const fetchInterviewDetails = useCallback(async () => {
    try {
      const response = await store.api.get(`/user/InterviewDetails/${interviewLink}`);
      if (response.data.success) {
        setInterviewDetails(response.data.interviewDetails);
        return response.data.interviewDetails;
      } else {
        throw new Error('Failed to fetch interview details');
      }
    } catch (error) {
      console.error('Error fetching interview details:', error);
      setError('Failed to load interview details. Please check your link and try again.');
      return null;
    }
  }, [interviewLink, store.api]);

  const fetchTokenAndCreateRoom = useCallback(async (details) => {
    if (!details || !details.candidateName || !details.jobName) {
      console.error('Invalid details provided:', details);
      throw new Error('Invalid interview details');
    }
  
    try {
      const response = await store.api.post('/ai/LiveKit/Token', { 
        interviewLink: interviewLink,
        candidateName: details.candidateName,
        jobName: details.jobName
      });
  
      if (response.data.accessToken && response.data.url) {
        setToken(response.data.accessToken);
        setUrl(response.data.url);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error fetching LiveKit token:', error);
      throw new Error('Failed to connect to the interview room. Please try again.');
    }
  }, [interviewLink, store.api]);
  
  useEffect(() => {
    const initializeInterview = async () => {
      setIsLoading(true);
      try {
        const details = await fetchInterviewDetails();
        if (details) {
          setInterviewDetails(details);
          await fetchTokenAndCreateRoom(details);
        } else {
          throw new Error('Failed to fetch interview details');
        }
      } catch (error) {
        console.error('Error initializing interview:', error);
        setError(error.message || 'An error occurred while setting up the interview. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    initializeInterview();
  }, [fetchInterviewDetails, fetchTokenAndCreateRoom]);

  const handleEndInterview = useCallback(() => {
    setIsInterviewEnded(true);
    if (room) {
      room.disconnect(); // Disconnect the participant from the room
    }
  }, [room]);

  const PreInterviewScreen = () => (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-2xl w-full">
        <h1 className="text-2xl font-bold mb-4">Welcome to Your Virtual Interview</h1>
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Candidate Details:</h2>
          <p><strong>Name:</strong> {interviewDetails?.candidateName}</p>
          <p><strong>Job Position:</strong> {interviewDetails?.jobName}</p>
          <p><strong>Interview Date:</strong> {new Date(interviewDetails?.interviewDate).toLocaleString()}</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Interview Guidelines:</h2>
          <ul className="list-disc list-inside">
            <li>Ensure you're in a quiet environment</li>
            <li>Check your internet connection</li>
            <li>Test your microphone before starting</li>
            <li>Be prepared to answer questions about your experience and skills</li>
            <li>Feel free to ask questions at the end of the interview</li>
          </ul>
        </div>
        <button 
          onClick={() => setIsInterviewStarted(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full"
        >
          Start Interview
        </button>
      </div>
    </div>
  );

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading interview details...</div>;
  }

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-700 mb-4">{error}</p>
          <button 
            onClick={() => history.push('/')} 
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  if (!isInterviewStarted) {
    return <PreInterviewScreen />;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {token && url ? (
        <LiveKitRoom
          video={true}
          audio={true}
          token={token}
          serverUrl={url}
          data-lk-theme="default"
          connectOptions={{ autoSubscribe: true }}
        >
          <RoomContent 
            interviewDetails={interviewDetails}
            interviewLink={interviewLink}
            onEndInterview={handleEndInterview}
          />
        </LiveKitRoom>
      ) : (
        <div className="flex-1 flex items-center justify-center">
          <p className="text-xl font-semibold">Connecting to interview room...</p>
        </div>
      )}
    </div>
  );
};

export default inject('store')(observer(VirtualInterviewRoom));